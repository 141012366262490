import { getSelectValue } from '@/utils/blocks';

interface BlobHolder {
  blob_enabled?: boolean;
  blob_colour?: string;
  blob_shape?: string;
  blob_rotation?: number;
  blob_side?: string;
  blob_position?: string;
}
export function useBlob() {
  const getDisplayBlob = (blobHolder: BlobHolder) => {
    return !!(blobHolder && blobHolder.blob_enabled);
  };

  const getBlobBindings = (blobHolder: BlobHolder) => {
    if (!blobHolder) {
      return {};
    }
    const color = getSelectValue(blobHolder.blob_colour, 'value', 'peach');
    const shape = getSelectValue(blobHolder.blob_shape, 'value', 'blob');
    const rotation = blobHolder.blob_rotation || 0;
    const side = getSelectValue(blobHolder.blob_side, 'value', 'left');
    const position = getSelectValue(blobHolder.blob_position, 'value', 'center');

    const classes = ['section-block__blob'];
    classes.push(`is-h-${side}`);
    classes.push(`is-v-${position}`);

    return {
      type: `${color}-light`,
      shape,
      rotation,
      class: classes,
    };
  };

  return {
    getDisplayBlob,
    getBlobBindings,
  };
}
