<template>
  <div class="blob" :class="rootClasses">
    <svg
      v-if="shape === 'donut'"
      :style="svgStyleBindings"
      width="1335"
      height="874"
      viewBox="0 0 1335 874"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M758.888 731.234C631.461 762.871 482.172 742.039 339.036 677.857C219.299 624.245 122.769 541.287 56.4416 425.797C44.2211 404.33 32.0005 382.862 21.0113 360.716C14.3489 347.127 17.868 337.377 31.8323 330.019C52.1415 319.226 72.9565 308.914 94.2613 299.881C143.953 279.01 193.197 257.047 240.596 231.33C252.764 224.778 265.207 218.563 277.736 212.724C306.719 199.158 313.329 201.229 331.171 227.379C346.732 250.47 362.293 273.562 378.854 295.829C402.49 327.417 433.028 351.07 468.631 367.405C562.061 410.714 658.099 419.432 756.899 388.159C776.274 381.952 794.481 373.229 811.042 362.495C868.752 324.687 916.03 276.732 948.036 214.764C950.955 209.328 953.179 203.453 956.098 198.016C962.558 186.405 969.032 183.536 983.049 187.696C999.394 192.518 1015.45 197.805 1031.45 203.7C1054.56 212.148 1077.61 221.204 1100.66 230.262C1142.26 246.822 1183.75 263.803 1225.2 280.595C1235.23 284.668 1245.27 288.741 1254.63 294.163C1270.05 302.773 1271.7 311.741 1262.65 328.514C1242.91 365.222 1223.16 401.93 1202.99 438.535C1184.24 472.631 1159.52 502.321 1131.08 528.686C1071.14 584.498 1008.64 636.917 934.149 673.185C883.206 698.119 845.178 711.958 758.888 731.234Z"
        fill="currentColor"
      />
    </svg>
    <svg
      v-if="shape === 'blob'"
      :style="svgStyleBindings"
      width="1283"
      height="1288"
      viewBox="0 0 1283 1288"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M285.724 920.459C236.846 842.474 218.059 753.423 211.111 661.656C203.684 563.568 190.582 457.996 244.479 375.725C299.153 292.266 403.222 264.285 495.8 227.089C594.707 187.35 698.031 118.283 798.829 152.999C899.312 187.606 944.186 302.183 990.95 397.625C1030.28 477.894 1040.73 564.425 1044.18 653.738C1047.74 745.909 1058.97 842.774 1011.18 921.648C961.652 1003.37 872.836 1050.17 783.456 1083.98C691.056 1118.93 590.027 1147.29 496.158 1116.45C402.814 1085.78 337.908 1003.72 285.724 920.459Z"
        fill="currentColor"
      />
    </svg>
    <svg
      v-if="shape === 'chevron'"
      :style="svgStyleBindings"
      width="1326"
      height="1277"
      viewBox="0 0 1326 1277"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.6893 556.168C74.6351 547.22 93.7123 537.522 113.364 528.953C208.08 487.939 298.926 438.926 392.171 394.571C467.161 358.898 542.666 323.957 617.715 288.68C637.204 279.568 655.51 279.383 675.336 288.851C769.59 333.932 864.312 377.807 958.522 423.137C1034.57 459.801 1109.8 498.127 1185.61 535.394C1201.02 542.94 1217.03 549.177 1232.44 556.724C1245.47 563.09 1247.99 570.727 1242.8 584.88C1241.31 588.995 1239.61 592.816 1237.76 596.742C1207.29 661.709 1176.87 726.426 1146.3 791.247C1140.76 803.023 1134.55 814.171 1124.3 822.691C1116.45 829.31 1109.53 831.33 1100.87 827.135C1023.76 789.257 946.315 752.481 870.254 712.984C807.995 680.629 742.464 655.169 679.207 624.829C655 613.19 632.025 617.97 608.894 628.769C549.193 656.534 489.551 684.695 429.482 711.626C394.277 727.414 357.557 740.111 322.206 756.002C277.793 775.86 234.218 797.535 190.129 818.478C185.062 820.947 179.746 823.373 174.414 825.154C168.392 827.201 161.632 825.391 158.837 820.793C142.582 793.935 125.49 767.448 112.084 739.024C89.1855 689.926 68.8432 639.468 47.5165 589.484C46.3097 586.832 45.6458 584.017 44.8357 581.305C41.4483 570.559 44.5582 563.121 55.6893 556.168Z"
        fill="currentColor"
      />
    </svg>
    <svg
      v-if="shape === 'circle'"
      :style="svgStyleBindings"
      width="773"
      height="773"
      viewBox="0 0 773 773"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="386.5" cy="386.5" r="386.5" fill="currentColor" />
    </svg>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  shape: {
    type: String,
    default: 'blob',
  },
  rotation: {
    type: [Number, String],
    default: 0,
  },
});

const rootClasses = computed(() => {
  const classes = [`is-shape-${props.shape}`];

  if (props.type) {
    classes.push(`is-${props.type}`);
  }

  return classes;
});

const svgStyleBindings = computed(() => {
  return {
    transform: `rotate(${props.rotation}deg)`,
    '-ms-transform': `rotate(${props.rotation}deg)`,
  };
});
</script>
